// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-content-lab-index-js": () => import("./../src/pages/content-lab/index.js" /* webpackChunkName: "component---src-pages-content-lab-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-on-the-ground-index-js": () => import("./../src/pages/on-the-ground/index.js" /* webpackChunkName: "component---src-pages-on-the-ground-index-js" */),
  "component---src-pages-request-index-js": () => import("./../src/pages/request/index.js" /* webpackChunkName: "component---src-pages-request-index-js" */)
}

